/*
https://flatuicolors.com/palette/se
*/

:root {
  --main: #575fcf;
  --dark-main: #3c40c6;
  --input-text: #485460;
  --input-border: #e6e9ec;
  --link: #2d31a1;
  --card-background: #e6e9ec;
  --confirm: #0be881;
  --warning: #ff5e57;
  --dark-warning: #ff3f34;

  --purple: #575fcf;
  --light-purple: #7177c5;
  --dark-purple: #3c40c6;
  --red: #ff5e57;
  --dark-red: #ff3f34;
  --dark-green: #05c46b;
  --darkest-green: #1a4f13;
  --light-gray: #e6e9ec;
  --gray: #d2dae2;
  --modest-gray: #808e9b;
  --dark-gray: #485460;
  --black: #1e272e;
  --transparent: #00000000;
}

html {
  font-size: 0.9em;
  font-weight: 400;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Inter", "Roboto", "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  display: grid;
  grid-template-columns: 1em auto 1em;
  grid-template-rows: auto;
}

h1 {
  margin: 0.3em 0 0.3em 0;
  letter-spacing: 0.5px;
}

h2 {
  font-size: 1.2em;
  letter-spacing: 0.5px;
}

h2 small {
  font-size: 0.6em;
}

a {
  color: var(--link);
}

button {
  padding: 0.5em 0.5em;
  background-color: var(--main);
  border: none;
  cursor: pointer;
  color: white;
  border-radius: 3px;
}

button:hover {
  background-color: var(--dark-main);
}

.button-selected {
  background-color: var(--dark-green);
  color: white;
}

.outlined {
  border: 1px solid var(--main);
  background-color: white;
  color: var(--dark-main);
}

.outlined:hover {
  color: white;
}

.danger {
  background-color: var(--warning);
}
.danger:hover {
  background-color: var(--dark-warning);
}

.inactive {
  background-color: var(--gray);
  color: var(--dark-gray);
  border: 1px solid var(--gray);
}
.inactive:hover {
  background-color: var(--light-gray);
  color: var(--dark-gray);
  border: 1px solid var(--dark-gray);
}

.App {
  grid-column: 2;
  grid-row: 1;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto auto;
}

.header {
  grid-row: 1;
}

.small-header {
  width: 100%;
  height: 5em;
}
.small-header h2 {
  float: left;
}

.small-header button {
  float: right;
  margin: 1.5em 0 0 0;
}

nav {
  margin-bottom: 1em;
  margin-top: 1em;
}

nav button {
  margin-right: 0.5em;
}

nav ul {
  list-style: none;
  margin: 0 0 2em 0;
  padding: 0;
}

nav li {
  float: left;
  margin-right: 1em;
}

nav li button {
  min-width: 8em;
}

nav .gap {
  display: inline-block;
  width: 2em;
}

nav .small-gap {
  display: inline-block;
  width: 0.5em;
}

.hamburger {
  width: 2em;
  height: 2em;
  float: right;
  margin-right: 0;
}

.hamburger span {
  display: block;
  height: 15%;
  width: 100%;
  margin: 0px 0 3px 0;
  background-color: var(--input-border);
}

#drawer .cross {
  font-size: 2em;
  padding: 0.2em;
  margin: 0.3em;
  position: absolute;
  top: 0;
  right: 0;
  color: var(--main);
  background-color: #fffff000;
}

#drawer {
  display: none;
  width: 60vw;
  height: 100vh;
  position: absolute;
  top: 0;
  right: 0;
  padding: 4em 1em 0 1em;
  background-color: #ffffff;
  box-shadow: 1px 0px 11px #808e9b;
  animation-duration: 0.5s;
  animation-name: slidein;
  z-index: 2;
}

#drawer li {
  float: none;
  margin: 0 0 0.5em 0;
  padding-left: 1em;
}

@keyframes slidein {
  from {
    margin-right: -50%;
  }

  to {
    margin-right: -0%;
  }
}
@keyframes slideout {
  from {
    margin-right: 0%;
  }

  to {
    margin-right: -50%;
  }
}

.content {
  grid-row: 2;
}

#message {
  color: var(--darkest-green);
  width: inherit;
  height: 3em;
  display: none;
  border-radius: 5px;
  padding: 1em 1em 0em 1em;
  margin: 3.5em 0 0 0;
  position: absolute;
  top: -100%;
  opacity: 0;
  transition: all 0.5s ease-out;
  animation: drop-down 0.5s forwards;
}
@keyframes drop-down {
  0% {
    top: -100%;
    opacity: 0;
  }
  100% {
    top: 0;
    opacity: 1;
  }
}
@keyframes drop-up {
  0% {
    top: 0;
    opacity: 1;
  }
  100% {
    top: -100%;
    opacity: 0;
  }
}

#message span {
  padding: 0 0 0 0;
}

#message .cross {
  color: var(--darkest-green);
  background-color: #fffff000;
  padding: 0 0.3em 0 0.3em;
  font-size: 1em;
  font-weight: bold;
  border: 1px solid var(--darkest-green);
  border-radius: 11px;
  margin: 0 0 0 15px;
}

.loading-text {
  text-align: center;
  padding-top: 20%;
  font-weight: bold;
}

.fade-out {
  opacity: 0;
}

.fade-in {
  transition: all 0.2s ease-in;
  transform: translate(0, 3px);
  opacity: 1;
}

/* LIST  */

.list {
  width: 100%;
  text-align: left;
  margin: 0 0 2em 0;
}

.list tbody tr:nth-child(odd) {
  background-color: var(--card-background);
}

.list tr {
  margin: 0.3em 0 0.3em 0;
}

/* SUB LIST  */

.sub-list {
  padding: 10px 14px 10px 14px;
  background-color: var(--card-background);
  border-radius: 16px;
  margin: 10px;
}

.sub-list h2 {
  margin: 8px 0 8px 0;
  font-size: 1.1em;
}

.sub-list .list {
  margin: 8px 0 8px 0;
}

.sub-list tbody tr:nth-child(odd) {
  background-color: #d3d9df;
}

/* TABLE */

table th button {
  background-color: transparent;
  color: #1e272e;
  font-weight: 700;
  font-family: -apple-system, BlinkMacSystemFont, "Inter", "Roboto";
  font-size: 1em;
}

table th button:hover {
  background-color: transparent;
  color: #1e272e;
  font-weight: 700;
}

table .comment {
  max-width: 400px;
}

form {
  width: 100%;
  margin-bottom: 2em;
}

form input {
  width: 96%;
  margin: 0 0 1em 0;
  padding: 0.5em 0 0.5em 4%;
  border: 1px solid var(--input-border);
  background-color: #ffffff;
  height: inherit;
  border-radius: 3px;
}

form input::placeholder {
  opacity: 1;
  color: var(--input-text);
}

form input:focus {
  outline: none; /*Chrome fix*/
  border: 1px var(--main) solid;
}

form input[type="checkbox"] {
  margin: 0.5em 0.5em 0.3em 0;
  width: 1.1em;
  height: 1.1em;
}

form textarea {
  width: 96%;
  margin: 0 0 1em 0;
  padding: 0.5em 0 0.5em 4%;
  border: 1px solid var(--input-border);
  background-color: #ffffff;
  height: inherit;
  border-radius: 3px;
}
form textarea::placeholder {
  opacity: 1;
  color: var(--input-text);
}

form textarea:focus {
  outline: none; /*Chrome fix*/
  border: 1px var(--main) solid;
}

form .comment {
  height: 5em;
  margin-bottom: 3em;
}

form .tag_row {
  margin: 1em 0 1em 0;
  display: inline-block;
}

form .tag {
  float: left;
  margin: 0 0.5em 0.5em 0;
  background-color: var(--light-gray);
  border-radius: 25px;
  padding: 0 0 0 16px;
  height: 2.9em;
}
form .tag button {
  width: 3em;
  height: 3em;
  border-radius: 25px;
  font-weight: bold;
  float: none;
}

form .tag span {
  margin-right: 0.5em;
}

form button {
  width: 100%;
  border-radius: 3px;
  margin: 0 0 0.3em 0;
}

form .back_button {
  float: none;
  width: 6em;
}

form .search-button {
  padding: 1em 0.5em;
}

form select {
  width: 96%;
  font-size: 13.3px;
  margin: 0 0 1em 0;
  padding: 0.5em 0 0.5em 4%;
  background-color: #ffffff;
  /* text-align: center;
  text-align-last: center;*/
  border-radius: 3px;
  border: 1px solid var(--input-border);
  color: var(--input-text);
  outline: none; /*Chrome fix*/

  /*Borrowed from Veiko, https://codepen.io/vkjgr/pen/VYMeXp*/
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  -webkit-appearance: none;
  -moz-appearance: none;

  background-image: linear-gradient(45deg, transparent 50%, var(--main) 50%),
    linear-gradient(135deg, var(--main) 50%, transparent 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position: calc(100% - 17px) calc(0.8em + 2px),
    calc(100% - 12px) calc(0.8em + 2px), calc(100% - 2.5em) 0.35em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
}

form select:focus {
  background-image: linear-gradient(
      45deg,
      var(--input-border) 50%,
      transparent 50%
    ),
    linear-gradient(135deg, transparent 50%, var(--input-border) 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position: calc(100% - 12px) 1em, calc(100% - 17px) 1em,
    calc(100% - 2.5em) 0.35em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  border: 1px solid var(--main);
}

form .group {
  width: inherit;
  padding: 0 0 1em 0;
}

form .field {
  position: relative;
  width: 100%;
  float: left;
}

form .field.comment {
  margin-top: 1em;
}

form label {
  position: absolute;
  left: 1.1em;
  top: 0.5em;
  pointer-events: none;
  font-size: 13.3px;
  transition: 100ms ease-in;
  color: var(--input-text);
}

.selectorLabel {
  display: flex;
  flex-direction: column;
  float: left;
  width: inherit;
}

.selectorLabel p {
  margin: 5px;
  font-size: 12.3px;
  color: var(--input-text);
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.relative {
  position: relative;
  left: 0em;
}

.mb-1 {
  margin-bottom: 1em;
}
.mb-2 {
  margin-bottom: 2em;
}

.mt-1 {
  margin-top: 1em;
}

.mt-2 {
  margin-top: 2em;
}

.mt-3 {
  margin-top: 3em;
}

form label[type="checkbox"] {
  transition: none;
  font-size: 13.3px;
  left: 2.1em;
  top: 0.4em;
  margin: 0 0.5em 0 0;
}

input[type="text"]:focus ~ label,
input[type="text"]:not([value=""]) ~ label {
  font-size: 12.3px;
  left: 0.5em;
  top: -0.9em;
  background-color: white;
  padding: 1px 2px 1px 2px;
}

select:not([value="0"]) ~ label {
  font-size: 12.3px;
  left: 0.5em;
  top: -0.9em;
  background-color: white;
  padding: 1px 2px 1px 2px;
}

textarea[type="text"]:focus ~ label,
textarea[type="text"]:not([data-value=""]) ~ label {
  font-size: 12.3px;
  left: 0.5em;
  top: -0.9em;
  background-color: white;
  padding: 1px 2px 1px 2px;
}

#form-warning {
  background-color: var(--red);
  width: 100%;
  display: table;
  padding: 0.8em 0em 0.8em 0.5em;
}

.shortcuts button {
  background-color: #00000000;
  border: 1px solid var(--purple);
  color: var(--purple);
  font-size: 0.8rem;
  font-weight: 600;
}
.shortcuts button:hover {
  background-color: var(--dark-purple);
  color: var(--light-gray);
}

.float-right {
  float: right !important;
  margin-right: 0;
  margin-left: 0.5em;
}

.link-space-left {
  margin-left: 1em;
}

.link-space {
  margin: 0 1em 0 1em;
}

.space {
  margin: 1em 0 1em 0;
}

@media only screen and (max-width: 767px) {
  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
  }

  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  tr {
    padding: 0.5em;
    border-radius: 3px;
  }

  .memberCardButtons {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
  }
  .memberCardButtons button {
    width: 100px;
  }

  .memberCardButtons a {
    display: contents;
  }

  .memberCardButtons button.grow-1 {
    flex-grow: 1;
  }

  .small-header {
    height: 7em;
  }
}

@media only screen and (max-width: 991px) {
  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
  }

  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  tr {
    padding: 0.5em;
    border-radius: 3px;
  }
  td {
    padding: 0.1em;
  }

  table .comment {
    max-width: 100%;
  }

  .machine-row {
    display: grid;
    grid-template-rows: auto auto auto auto auto auto;
    grid-template-columns: 30% 35% 35%;
  }
  .machine-row .row-1 {
    grid-row: 1;
  }
  .machine-row td:nth-child(2).row-1 {
    grid-column: 2 / span 3;
  }
  .machine-row .row-2 {
    grid-row: 2;
  }
  .machine-row td:nth-child(3).row-2 {
    grid-column: 1 / span 2;
  }
  .machine-row td:nth-child(4).row-2 {
    grid-column: 3 / span 2;
  }
  .machine-row .row-3 {
    grid-row: 3;
  }
  .machine-row .row-7 {
    grid-row: 7;
    grid-column: 1 / span 3;
    padding: 6px;
  }
  .machine-row .row-5 {
    grid-row: 5;
  }
  .machine-row .row-6 {
    grid-row: 6;
  }
  .list tbody tr:nth-child(even) {
    background-color: var(--card-background);
  }

  .list tbody tr .row-7 {
    background-color: #ffffff;
    border-radius: 4px;
    margin-top: 5px;
  }
}

@media only screen and (min-width: 1024px) {
  #root {
    display: grid;
    grid-template-columns: 1% auto 1%;
    grid-template-rows: auto;
    font-size: 0.9em;
  }

  table {
    border-collapse: collapse;
  }
  th {
    width: auto;
    padding: 0 0 0.5em 0;
  }

  td {
    padding: 0 0 0 0.3em;
  }

  thead tr {
    position: relative;
    top: 0;
    left: 0;
  }

  .machine-list .comment {
    max-width: 20em;
  }

  .list .narrow {
    max-width: 500px;
  }

  nav button {
    min-width: 4em;
  }

  form input {
    float: left;
    margin: 0 0.5em 0.3em 0;
    padding: 0.5em 0 0.5em 1em;
    width: 89%;
    height: 100%;
  }

  form textarea {
    float: left;
    margin: 0 0.5em 0.3em 0;
    padding: 0.5em 0 0.5em 1em;
    width: 86%;
    height: 100%;
  }

  form button {
    float: left;
    width: auto;
    margin: 0 0.3em 0.3em 0;
  }

  form .search-button {
    padding: 0.55em 0.5em;
  }

  form select {
    width: 14.5em;
    float: left;
    margin: 0 0.5em 0.3em 0;
    padding: 0.5em 0 0.5em 1em;
  }

  .selectorLabel {
    width: auto;
  }

  .country-select {
    width: 20%;
  }

  @-moz-document url-prefix() {
    form select {
      width: 15%;
      float: left;
      padding: 0.4em 0 0.4em 0;
      margin: 0 0.3em 0.3em 0;
    }
  }

  form .tag_row {
    margin: 1em 0 1em 0;
    display: block;
    width: 100%;
    float: left;
  }

  form .tag button {
    margin: 0;
  }

  form .group {
    padding: 0 0 2em 0;
    float: left;
  }

  form .field {
    width: 15em;
  }

  form .field.large {
    width: 20em;
  }

  form .comment {
    width: 33em;
    height: 10em;
  }

  .search-form {
    display: grid;
    grid-template-columns: 3% 11% 7% 5% 10% 14% 14% 10% 8% 5% 5%;
    column-gap: 0.5em;
    grid-template-rows: 2em;
    grid-auto-flow: column;
  }

  .search-form.company {
    grid-template-columns: 5% 11% 7% 5% 10% 14% 8% 10% 8%;
  }

  .search-form.members {
    grid-template-columns: 5% 5% 5% 10% 10% 10% 10% 10% 8% 5% 8% 8%;
  }

  .search-form input {
    width: auto;
    padding: 0 0 0 1em;
    margin: 0;
  }
  .search-form select {
    width: 90%;
    float: left;
    padding: 0 0 0 1em;
    margin: 0;
    text-align: unset;
    text-align-last: unset;
  }

  .search-form button {
    float: left;
    width: auto;
    height: 100%;
    padding: 6px;
    margin: 0;
  }

  .list tr {
    height: 2.5em;
  }
}

@media only screen and (min-width: 1280px) {
  h2 {
    font-size: 1.5em;
  }

  #root {
    display: grid;
    grid-template-columns: 1vw auto 1vw;
    grid-template-rows: auto;
    font-size: 1em;
  }

  .header {
    height: 3em;
  }

  .search-form {
    display: grid;
    grid-template-columns: 5% 10% 7% 4% 10% 11% 12% 8% 7% 5% 5%;
    column-gap: 0.5em;
    grid-template-rows: 2em;
    grid-auto-flow: column;
  }

  #message {
    border-radius: 3px;
    height: 1.3em;
    padding: 0.5em 0.5em 0.5em 0.5em;
    margin-bottom: 1em;
  }
}

@media only screen and (min-width: 1920px) {
  #root {
    display: grid;
    grid-template-columns: 5% auto 5%;
    grid-template-rows: auto;
    font-size: 1em;
  }
}

@media only screen and (min-width: 2200px) {
  #root {
    display: grid;
    grid-template-columns: 20% auto 20%;
    grid-template-rows: auto;
    font-size: 1em;
  }
}
